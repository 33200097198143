<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <v-form lazy-validation>
          <div class="row">
            <div class="col-md-3">
              <v-text-field
                disabled
                type="text"
                v-model="frequencia_cardiaca"
                label="Frequência cardíaca"
                required
              ></v-text-field>
            </div>
            <div class="col-md-3">
              <v-text-field
                disabled
                type="text"
                v-model="temperatura"
                label="Temperatura"
                required
              ></v-text-field>
            </div>

            <div class="col-md-3">
              <v-text-field
                disabled
                type="text"
                v-model="peso"
                label="Peso"
                required
              ></v-text-field>
            </div>

            <div class="col-md-3">
              <v-text-field
                disabled
                type="text"
                v-model="altura"
                label="Altura"
                required
              ></v-text-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <v-text-field
                disabled
                type="text"
                v-model="saturacao_oxigenio"
                label="Saturação de Oxigênio"
                required
              ></v-text-field>
            </div>

            <div class="col-md-4">
              <v-text-field
                disabled
                type="text"
                v-model="frequencia_respiratoria"
                label="Frequência respiratória"
                required
              ></v-text-field>
            </div>

            <div class="col-md-4">
              <v-text-field
                disabled
                type="text"
                v-model="pressao_arterial"
                label="Pressão arterial"
                required
              ></v-text-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <b-form-textarea
                disabled
                v-model="observacoes_triagem"
                placeholder="Digite suas observações"
                rows="3"
                required
                max-rows="6"
                label="Observações"
              ></b-form-textarea>
            </div>
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import * as ambulatorio from "../../../core/services/ambulatorioAPI.service";
export default {
  name: "visualizarDadosTriagem",
  props: {
    idConsulta: Number,
  },
  async created() {
    try {
      const response = await ambulatorio.prontuarioConsultaShowTriagem(
        this.idConsulta
      );

      console.log(response.data);
      this.frequencia_cardiaca =
        response.data.historico_triagem.frequencia_cardiaca;
      this.temperatura = response.data.historico_triagem.temperatura;
      this.peso = response.data.historico_triagem.peso;
      this.altura = response.data.historico_triagem.altura;
      this.saturacao_oxigenio =
        response.data.historico_triagem.saturacao_oxigenio;
      this.frequencia_respiratoria =
        response.data.historico_triagem.frequencia_respiratoria;
      this.observacoes_triagem =
        response.data.historico_triagem.observacoes_triagem;
      this.pressao_arterial = response.data.historico_triagem.pressao_arterial;
    } catch (error) {
      console.log(error);
    }
  },
  data() {
    return {
      nomePaciente: "",
      frequencia_cardiaca: "",
      temperatura: "",
      peso: "",
      altura: "",
      saturacao_oxigenio: "",
      frequencia_respiratoria: "",
      pressao_arterial: "",
      observacoes_triagem: "",
      paciente_id: "",
    };
  },
};
</script>
